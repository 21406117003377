"use client";

import "./globals.css";
import { Lato } from "next/font/google";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { GoogleAnalytics } from "@next/third-parties/google";
import Link from "next/link";

const lato = Lato({ subsets: ["latin"], weight: ["300", "400", "700"] });

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("cookiesAccepted") === "true") {
      setAnalyticsEnabled(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setAnalyticsEnabled(true);
  };

  return (
    <html lang="en">
      <body className={lato.className}>
        {children}
        {analyticsEnabled && (
          <GoogleAnalytics
            gaId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID as string}
          />
        )}
        <CookieConsent
          buttonStyle={{
            background: "#5a64ba",
            color: "#fff",
          }}
          buttonText="Přijmout"
          declineButtonText="Odmítnout"
          enableDeclineButton
          onAccept={handleAcceptCookies}
          onDecline={() => localStorage.setItem("cookiesAccepted", "false")}
        >
          Tento web používá cookies ke zlepšení uživatelské zkušenosti.
          Pokračováním souhlasíte s naší politikou{" "}
          <Link href={"/cookies"} target={"_blank"} className={"underline"}>
            cookies
          </Link>
          .
        </CookieConsent>
      </body>
    </html>
  );
}
